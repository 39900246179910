var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      on: {
        submit: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.onChildSubmit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "mb-4" }, [
        _c("hr", {
          staticClass: "hr-text",
          attrs: { "data-content": "DATI BADANTE" }
        })
      ]),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "username-input-group",
                    label: _vm.$t("username"),
                    "label-for": "username"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "username",
                      name: "username",
                      disabled: "",
                      type: "text",
                      state: _vm.validateState("username"),
                      "aria-describedby": "username-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.username.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.username, "$model", $$v)
                      },
                      expression: "$v.form.username.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "username-live-feedback" } },
                    [
                      !_vm.$v.form.username.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "password1-input-group",
                    label: _vm.$t("login.password"),
                    "label-for": "password1"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "password1",
                      name: "password1",
                      type: "password",
                      autocomplete: "off",
                      state: _vm.validateState("password1"),
                      "aria-describedby": "password1-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.password1.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.password1, "$model", $$v)
                      },
                      expression: "$v.form.password1.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "password1-live-feedback" } },
                    [
                      !_vm.$v.form.password1.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "password2-input-group",
                    label: _vm.$t("register.password_confirm"),
                    "label-for": "password2"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "password2",
                      name: "password2",
                      type: "password",
                      autocomplete: "off",
                      state: _vm.validateState("password2"),
                      "aria-describedby": "password2-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.password2.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.password2, "$model", $$v)
                      },
                      expression: "$v.form.password2.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "password2-live-feedback" } },
                    [
                      !_vm.$v.form.password2.sameAsPassword
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("validation.not_same_password")) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.password2.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "first_name-input-group",
                    label: _vm.$t("register.name"),
                    "label-for": "first_name"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "first_name",
                      name: "first_name",
                      type: "text",
                      state: _vm.validateState("first_name"),
                      "aria-describedby": "first_name-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.first_name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.first_name, "$model", $$v)
                      },
                      expression: "$v.form.first_name.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "first_name-live-feedback" } },
                    [
                      !_vm.$v.form.first_name.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "last_name-input-group",
                    label: _vm.$t("register.surname"),
                    "label-for": "last_name"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "last_name",
                      name: "last_name",
                      type: "text",
                      state: _vm.validateState("last_name"),
                      "aria-describedby": "last_name-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.last_name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.last_name, "$model", $$v)
                      },
                      expression: "$v.form.last_name.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "last_name-live-feedback" } },
                    [
                      !_vm.$v.form.last_name.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "email-input-group",
                    label: _vm.$t("email"),
                    "label-for": "email"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "email",
                      name: "email",
                      type: "text",
                      state: _vm.validateState("email"),
                      "aria-describedby": "email-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.email.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.email, "$model", $$v)
                      },
                      expression: "$v.form.email.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "email-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "phone_number-input-group",
                    label: _vm.$t("register.phone"),
                    "label-for": "phone_number"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "phone_number",
                      name: "phone_number",
                      type: "text",
                      state: _vm.validateState("phone_number"),
                      "aria-describedby": "phone_number-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.phone_number.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.phone_number, "$model", $$v)
                      },
                      expression: "$v.form.phone_number.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "phone_number-live-feedback" } },
                    [
                      !_vm.$v.form.phone_number.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "birth_date-input-group",
                    label: _vm.$t("register.birth_date"),
                    "label-for": "birth_date"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "birth_date",
                      type: "date",
                      state: _vm.validateState("birth_date"),
                      "aria-describedby": "birth_date-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.birth_date.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.birth_date, "$model", $$v)
                      },
                      expression: "$v.form.birth_date.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "birth_date-live-feedback" } },
                    [
                      !_vm.$v.form.birth_date.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.birth_date.minValue
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.under_age")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "picture-input-group",
                    label: _vm.$t("register.photo"),
                    "label-for": "picture"
                  }
                },
                [
                  _c("b-form-file", {
                    attrs: {
                      id: "picture",
                      "browse-text": _vm.$t("browse"),
                      state: _vm.validateState("picture"),
                      placeholder: _vm.$t("file_placeholder"),
                      "drop-placeholder": _vm.$t("drop_placeholder"),
                      "aria-describedby": "picture-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.picture.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.picture, "$model", $$v)
                      },
                      expression: "$v.form.picture.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "picture-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  ),
                  _vm.pictureUrl
                    ? _c("b-avatar", {
                        staticClass: "mt-2 text-center",
                        attrs: { src: _vm.pictureUrl, size: "6rem" }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "city_birth-input-group",
                    label: _vm.$t("register.city_birth"),
                    "label-for": "city_birth"
                  }
                },
                [
                  _c(
                    "multiselect",
                    {
                      class: { "is-invalid": _vm.$v.form.city_birth.$error },
                      attrs: {
                        id: "city_birth",
                        "close-on-select": true,
                        "clear-on-select": false,
                        multiple: false,
                        placeholder: _vm.$t("select_placeholder"),
                        "select-label": _vm.$t("multiselect.selectLabel"),
                        "selected-label": _vm.$t("multiselect.selectedLabel"),
                        "deselect-label": _vm.$t("multiselect.deselectLabel"),
                        label: "comune",
                        "track-by": "id",
                        options: _vm.cities,
                        state: _vm.validateState("city_birth"),
                        "aria-describedby": "city_birth-live-feedback"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function(ref) {
                            var option = ref.option
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(option.comune) +
                                  " (" +
                                  _vm._s(option.province) +
                                  ") "
                              )
                            ]
                          }
                        },
                        {
                          key: "singleLabel",
                          fn: function(ref) {
                            var option = ref.option
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(option.comune) +
                                  " (" +
                                  _vm._s(option.province) +
                                  ") "
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.$v.form.city_birth.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.form.city_birth, "$model", $$v)
                        },
                        expression: "$v.form.city_birth.$model"
                      }
                    },
                    [
                      _c("template", { slot: "noOptions" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("multiselect.noOptionsLabel")) +
                            " "
                        )
                      ]),
                      _c("template", { slot: "noResult" }, [
                        _vm._v(
                          _vm._s(_vm.$t("multiselect.noResultLabel")) + " "
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "city_birth-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "city_live-input-group",
                    label: _vm.$t("register.city_live"),
                    "label-for": "city_live"
                  }
                },
                [
                  _c(
                    "multiselect",
                    {
                      class: { "is-invalid": _vm.$v.form.city_live.$error },
                      attrs: {
                        id: "city_live",
                        "close-on-select": true,
                        "clear-on-select": false,
                        multiple: false,
                        placeholder: _vm.$t("select_placeholder"),
                        "select-label": _vm.$t("multiselect.selectLabel"),
                        "selected-label": _vm.$t("multiselect.selectedLabel"),
                        "deselect-label": _vm.$t("multiselect.deselectLabel"),
                        label: "comune",
                        "track-by": "id",
                        options: _vm.cities,
                        state: _vm.validateState("city_live"),
                        "aria-describedby": "city_live-live-feedback"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function(ref) {
                            var option = ref.option
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(option.comune) +
                                  " (" +
                                  _vm._s(option.province) +
                                  ") "
                              )
                            ]
                          }
                        },
                        {
                          key: "singleLabel",
                          fn: function(ref) {
                            var option = ref.option
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(option.comune) +
                                  " (" +
                                  _vm._s(option.province) +
                                  ") "
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.$v.form.city_live.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.form.city_live, "$model", $$v)
                        },
                        expression: "$v.form.city_live.$model"
                      }
                    },
                    [
                      _c("template", { slot: "noOptions" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("multiselect.noOptionsLabel")) +
                            " "
                        )
                      ]),
                      _c("template", { slot: "noResult" }, [
                        _vm._v(
                          _vm._s(_vm.$t("multiselect.noResultLabel")) + " "
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "city_live-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "9" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "address-input-group",
                    label: _vm.$t("register.address"),
                    "label-for": "address"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "address",
                      name: "address",
                      type: "text",
                      state: _vm.validateState("address"),
                      "aria-describedby": "address-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.address.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.address, "$model", $$v)
                      },
                      expression: "$v.form.address.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "address-live-feedback" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("validation.max_length")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "street_number-input-group",
                    label: _vm.$t("register.street_number"),
                    "label-for": "street_number"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "street_number",
                      name: "street_number",
                      type: "text",
                      state: _vm.validateState("street_number"),
                      "aria-describedby": "street_number-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.street_number.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.street_number, "$model", $$v)
                      },
                      expression: "$v.form.street_number.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "street_number-live-feedback" } },
                    [
                      !_vm.$v.form.street_number.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "fiscal_code-input-group",
                    label: _vm.$t("register.social_account"),
                    "label-for": "fiscal_code"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "fiscal_code",
                      name: "fiscal_code",
                      type: "text",
                      state: _vm.validateState("fiscal_code"),
                      "aria-describedby": "fiscal_code-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.fiscal_code.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.fiscal_code, "$model", $$v)
                      },
                      expression: "$v.form.fiscal_code.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "fiscal_code-live-feedback" } },
                    [
                      !_vm.$v.form.fiscal_code.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.fiscal_code.minLength
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("validation.min_length")) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "country-input-group",
                    label: _vm.$t("register.nationality"),
                    "label-for": "country"
                  }
                },
                [
                  _c("multiselect", {
                    class: { "is-invalid": _vm.$v.form.country.$error },
                    attrs: {
                      id: "country",
                      "close-on-select": true,
                      "clear-on-select": false,
                      multiple: false,
                      placeholder: _vm.$t("select_placeholder"),
                      "select-label": _vm.$t("multiselect.selectLabel"),
                      "selected-label": _vm.$t("multiselect.selectedLabel"),
                      "deselect-label": _vm.$t("multiselect.deselectLabel"),
                      object: false,
                      options: _vm.countries,
                      state: _vm.validateState("country"),
                      "aria-describedby": "country-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.country.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.country, "$model", $$v)
                      },
                      expression: "$v.form.country.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "country-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "availability-input-group",
                    label: _vm.$t("register.availability"),
                    "label-for": "availability"
                  }
                },
                [
                  _c("WeekAvailabilitySelect", {
                    ref: "availability",
                    attrs: {
                      id: "availability",
                      label: _vm.$t("availability_placeholder"),
                      v: _vm.$v.form.availability,
                      state: _vm.validateState("availability"),
                      "aria-describedby": "availability-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.availability.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.availability, "$model", $$v)
                      },
                      expression: "$v.form.availability.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "availability-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "working_provinces-input-group",
                    label: _vm.$t("register.work_provinces"),
                    "label-for": "working_provinces"
                  }
                },
                [
                  _c(
                    "multiselect",
                    {
                      class: {
                        "is-invalid": _vm.$v.form.working_provinces.$error
                      },
                      attrs: {
                        id: "working_provinces",
                        "close-on-select": true,
                        "clear-on-select": false,
                        multiple: true,
                        placeholder: _vm.$t(
                          "register.work_provinces_placeholder"
                        ),
                        "select-label": _vm.$t("multiselect.selectLabel"),
                        "selected-label": _vm.$t("multiselect.selectedLabel"),
                        "deselect-label": _vm.$t("multiselect.deselectLabel"),
                        object: false,
                        options: _vm.provinces,
                        state: _vm.validateState("working_provinces"),
                        "aria-describedby": "working_provinces-live-feedback"
                      },
                      model: {
                        value: _vm.$v.form.working_provinces.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.form.working_provinces, "$model", $$v)
                        },
                        expression: "$v.form.working_provinces.$model"
                      }
                    },
                    [
                      _c("template", { slot: "noOptions" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("multiselect.noOptionsLabel")) +
                            " "
                        )
                      ]),
                      _c("template", { slot: "noResult" }, [
                        _vm._v(
                          _vm._s(_vm.$t("multiselect.noResultLabel")) + " "
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "working_provinces-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "affiliate-input-group",
                    label: _vm.$t("register.affiliate"),
                    "label-for": "affiliate"
                  }
                },
                [
                  _c(
                    "multiselect",
                    {
                      class: { "is-invalid": _vm.$v.form.affiliate.$error },
                      attrs: {
                        id: "affiliate",
                        "close-on-select": true,
                        "clear-on-select": false,
                        multiple: false,
                        placeholder: _vm.$t("register.affiliate"),
                        "select-label": _vm.$t("multiselect.selectLabel"),
                        "selected-label": _vm.$t("multiselect.selectedLabel"),
                        "deselect-label": _vm.$t("multiselect.deselectLabel"),
                        options: _vm.affiliates,
                        state: _vm.validateState("affiliate"),
                        "aria-describedby": "affiliate-live-feedback"
                      },
                      model: {
                        value: _vm.$v.form.affiliate.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.form.affiliate, "$model", $$v)
                        },
                        expression: "$v.form.affiliate.$model"
                      }
                    },
                    [
                      _c("template", { slot: "noOptions" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("multiselect.noOptionsLabel")) +
                            " "
                        )
                      ]),
                      _c("template", { slot: "noResult" }, [
                        _vm._v(
                          _vm._s(_vm.$t("multiselect.noResultLabel")) + " "
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "affiliate-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c("h3", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.$t("register.select_services")))
              ]),
              _c(
                "b-form-group",
                { attrs: { label: "" } },
                [
                  _vm._l(_vm.services, function(service, key) {
                    return _c(
                      "b-form-checkbox",
                      {
                        key: key,
                        attrs: {
                          value: service.categoria.name,
                          state: _vm.validateState("servizi"),
                          "aria-describedby": "servizi-live-feedback"
                        },
                        model: {
                          value: _vm.$v.form.servizi.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.form.servizi, "$model", $$v)
                          },
                          expression: "$v.form.servizi.$model"
                        }
                      },
                      [_vm._v(" " + _vm._s(service.categoria.name) + " ")]
                    )
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    {
                      attrs: {
                        state: _vm.validateState("servizi"),
                        id: "servizi-live-feedback"
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("validation.min_elements")) + " "
                      )
                    ]
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "referrer-input-group",
                    label: _vm.$t("register.referrer"),
                    "label-for": "referrer"
                  }
                },
                [
                  _c(
                    "multiselect",
                    {
                      class: { "is-invalid": _vm.$v.form.referrer.$error },
                      attrs: {
                        id: "referrer",
                        "close-on-select": true,
                        "clear-on-select": false,
                        multiple: false,
                        placeholder: _vm.$t("register.referrer"),
                        "select-label": _vm.$t("multiselect.selectLabel"),
                        "selected-label": _vm.$t("multiselect.selectedLabel"),
                        "deselect-label": _vm.$t("multiselect.deselectLabel"),
                        options: _vm.referrers,
                        state: _vm.validateState("referrer"),
                        "aria-describedby": "referrer-live-feedback"
                      },
                      model: {
                        value: _vm.$v.form.referrer.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.form.referrer, "$model", $$v)
                        },
                        expression: "$v.form.referrer.$model"
                      }
                    },
                    [
                      _c("template", { slot: "noOptions" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("multiselect.noOptionsLabel")) +
                            " "
                        )
                      ]),
                      _c("template", { slot: "noResult" }, [
                        _vm._v(
                          _vm._s(_vm.$t("multiselect.noResultLabel")) + " "
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "referrer-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c("h3", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.$t("register.bio")))
              ]),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "experience-input-group",
                    label: _vm.$t("register.experience"),
                    "label-for": "experience"
                  }
                },
                [
                  _c(
                    "multiselect",
                    {
                      class: { "is-invalid": _vm.$v.form.experience.$error },
                      attrs: {
                        id: "experience",
                        "close-on-select": true,
                        "clear-on-select": false,
                        multiple: false,
                        placeholder: _vm.$t("register.experience"),
                        "select-label": _vm.$t("multiselect.selectLabel"),
                        "selected-label": _vm.$t("multiselect.selectedLabel"),
                        "deselect-label": _vm.$t("multiselect.deselectLabel"),
                        options: _vm.experiences,
                        state: _vm.validateState("experience"),
                        "aria-describedby": "experience-live-feedback"
                      },
                      model: {
                        value: _vm.$v.form.experience.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.form.experience, "$model", $$v)
                        },
                        expression: "$v.form.experience.$model"
                      }
                    },
                    [
                      _c("template", { slot: "noOptions" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("multiselect.noOptionsLabel")) +
                            " "
                        )
                      ]),
                      _c("template", { slot: "noResult" }, [
                        _vm._v(
                          _vm._s(_vm.$t("multiselect.noResultLabel")) + " "
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "experience-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: "" } },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      rows: "10",
                      state: _vm.validateState("bio"),
                      "aria-describedby": "bio-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.bio.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.bio, "$model", $$v)
                      },
                      expression: "$v.form.bio.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "bio-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("register.cv") } },
                [
                  _c("b-form-file", {
                    attrs: {
                      id: "cv",
                      "browse-text": _vm.$t("browse"),
                      state: _vm.validateState("cv"),
                      placeholder: _vm.$t("file_placeholder"),
                      "drop-placeholder": _vm.$t("drop_placeholder"),
                      "aria-describedby": "cv-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.cv.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.cv, "$model", $$v)
                      },
                      expression: "$v.form.cv.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "cv-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-2 text-right" },
        [
          _c("b-button", { attrs: { type: "submit", variant: "success" } }, [
            _vm._v(_vm._s(_vm.$t("update")) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }