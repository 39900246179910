<template>
  <b-form @submit.stop.prevent="onChildSubmit">
    <div class="mb-4">
      <hr data-content="DATI FAMIGLIA" class="hr-text" />
    </div>
    <!-- Username -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="username-input-group"
          :label="$t('username')"
          label-for="username"
        >
          <b-form-input
            id="username"
            name="username"
            type="text"
            readonly
            v-model="$v.form.username.$model"
            :state="validateState('username')"
            aria-describedby="username-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="username-live-feedback">
            <div v-if="!$v.form.username.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Password + Password Confirmation -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          id="password-input-group"
          :label="$t('login.password')"
          label-for="password"
        >
          <b-form-input
            id="password"
            name="password"
            type="password"
            autocomplete="off"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="password-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="password-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="password_confirm-input-group"
          :label="$t('register.password_confirm')"
          label-for="password_confirm"
        >
          <b-form-input
            id="password_confirm"
            name="password_confirm"
            type="password"
            autocomplete="off"
            v-model="$v.form.password_confirm.$model"
            :state="validateState('password_confirm')"
            aria-describedby="password_confirm-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="password_confirm-live-feedback">
            <div v-if="!$v.form.password_confirm.sameAsPassword">
              {{ $t("validation.not_same_password") }}
            </div>
            <div v-if="!$v.form.password_confirm.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Name + Surname -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          id="name-input-group"
          :label="$t('register.name')"
          label-for="name"
        >
          <b-form-input
            id="name"
            name="name"
            type="text"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            aria-describedby="name-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="name-live-feedback">
            <div v-if="!$v.form.name.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="surname-input-group"
          :label="$t('register.surname')"
          label-for="surname"
        >
          <b-form-input
            id="surname"
            name="surname"
            type="text"
            v-model="$v.form.surname.$model"
            :state="validateState('surname')"
            aria-describedby="surname-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="surname-live-feedback">
            <div v-if="!$v.form.surname.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Email + Phone -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="email-input-group"
          :label="$t('email')"
          label-for="email"
        >
          <b-form-input
            id="email"
            name="email"
            type="text"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="email-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="email-live-feedback">
            <div v-if="!$v.form.email.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.email.email">
              {{ $t("validation.email") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="phone-input-group"
          :label="$t('register.phone')"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            name="phone"
            type="text"
            v-model="$v.form.phone.$model"
            :state="validateState('phone')"
            aria-describedby="phone-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="phone-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- City + Address -->
    <b-form-row>
      <b-col lg="6">
        <!-- <SearchableSelect
          id="city_id"
          :label="$t('register.city')"
          v-model="form.city_id"
          :v="$v.form.city_id"
          :options="cities"
          label-field="comune"
          key-field="id"
          :state="validateState('city_id')"
        /> -->
        <b-form-group
          id="city-input-group"
          :label="$t('register.city')"
          label-for="city"
        >
          <multiselect
            id="city"
            :class="{ 'is-invalid': $v.form.city.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :multiple="false"
            :placeholder="$t('select_placeholder')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            label="comune"
            track-by="id"
            :options="cities"
            v-model="$v.form.city.$model"
            :state="validateState('city')"
            aria-describedby="city_id-live-feedback"
          >
            <template slot="option" slot-scope="{ option }">
              {{ option.comune }} ({{ option.province }})
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.comune }} ({{ option.province }})
            </template>

            <!-- @see https://stackoverflow.com/questions/68783802/vue-multiselect-template-slot-clear-does-not-work -->
            <template slot="noOptions">
              {{ $t("multiselect.noOptionsLabel") }}
            </template>
            <template slot="noResult"
              >{{ $t("multiselect.noResultLabel") }}
            </template>
          </multiselect>
          <b-form-invalid-feedback id="city-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="address-input-group"
          :label="$t('register.address')"
          label-for="address"
        >
          <b-form-input
            id="address"
            name="address"
            type="text"
            v-model="$v.form.address.$model"
            :state="validateState('address')"
            aria-describedby="address-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="address-live-feedback">
            {{ $t("validation.max_length") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Street Number + Social Account -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="street_number-input-group"
          :label="$t('register.street_number')"
          label-for="street_number"
        >
          <b-form-input
            id="street_number"
            name="street_number"
            type="text"
            v-model="$v.form.street_number.$model"
            :state="validateState('street_number')"
            aria-describedby="street_number-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="street_number-live-feedback">
            <div v-if="!$v.form.street_number.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="fiscal_code-input-group"
          :label="$t('register.social_account')"
          label-for="fiscal_code"
        >
          <b-form-input
            id="fiscal_code"
            name="fiscal_code"
            type="text"
            v-model="$v.form.fiscal_code.$model"
            :state="validateState('fiscal_code')"
            aria-describedby="fiscal_code-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="fiscal_code-live-feedback">
            <div v-if="!$v.form.fiscal_code.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.fiscal_code.minLength">
              {{ $t("validation.min_length") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Notes -->
    <b-form-row>
      <b-col lg="12">
        <h3 class="text-center">{{ $t("register.notes") }}</h3>
        <b-form-group label="">
          <b-form-textarea
            rows="10"
            :placeholder="$t('register.notes_placeholder')"
            v-model="$v.form.notes.$model"
            :state="validateState('notes')"
            aria-describedby="notes-live-feedback"
          >
          </b-form-textarea>
          <b-form-invalid-feedback id="notes-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Actions -->
    <div class="mb-2 text-right">
      <b-button type="submit" variant="success">{{ $t("update") }} </b-button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  email,
  maxLength,
  minLength,
  required,
  sameAs,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  name: "CaregiverForm",
  props: {
    formData: {
      type: Object,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: this.getDefaultFormData(),
    };
  },
  computed: {
    ...mapGetters({
      cities: "cities/cities",
    }),
  },
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        // required,
      },
      password_confirm: {
        // required,
        sameAsPassword: sameAs("password"),
      },
      name: {
        required,
      },
      surname: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        // @todo: Phone validation
      },
      city: {
        required,
      },
      address: {
        maxLength: maxLength(255),
      },
      street_number: {
        required,
      },
      fiscal_code: {
        required,
        minLength: minLength(16),
        maxLength: maxLength(16),
      },
      notes: {},
    },
  },
  methods: {
    ...mapActions({
      getCities: "cities/getCities",
    }),
    getDefaultFormData() {
      return {
        id: this.formData.id,
        username: this.formData.username,
        password: "",
        password_confirm: "",
        name: this.formData.first_name,
        surname: this.formData.last_name,
        email: this.formData.email,
        phone: this.formData.phone_number,
        city: null,
        city_id: this.formData.city_id,
        address: this.formData.address,
        street_number: this.formData.street_number,
        fiscal_code: this.formData.fiscal_code,
        notes: this.formData.note,
      };
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onChildSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      // API mapping
      this.form.city_id = this.form.city ? this.form.city.id : null;
      this.form.first_name = this.form.name;
      this.form.last_name = this.form.surname;
      this.form.phone_number = this.form.phone;
      this.form.note = this.form.notes;
      this.onSubmit(this.form);
    },
  },
  async mounted() {
    if (!this.cities || !this.cities.length) {
      // Load from API
      console.log("Loading cities from API");
      await this.getCities();
    }
    this.form.city = this.cities.find(
      (item) => item.id === parseInt(this.formData.city_id)
    );
  },
};
</script>

<style scoped>
hr.hr-text {
  position: relative;
  border: none;
  height: 1px;
  background: #999;
}

hr.hr-text::before {
  content: attr(data-content);
  display: inline-block;
  background: #fff;
  font-weight: bold;
  font-size: 0.85rem;
  color: #999;
  border-radius: 30rem;
  padding: 0.2rem 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
