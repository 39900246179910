var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      on: {
        submit: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.onChildSubmit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "mb-4" }, [
        _c("hr", {
          staticClass: "hr-text",
          attrs: { "data-content": "DATI FAMIGLIA" }
        })
      ]),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "username-input-group",
                    label: _vm.$t("username"),
                    "label-for": "username"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "username",
                      name: "username",
                      type: "text",
                      readonly: "",
                      state: _vm.validateState("username"),
                      "aria-describedby": "username-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.username.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.username, "$model", $$v)
                      },
                      expression: "$v.form.username.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "username-live-feedback" } },
                    [
                      !_vm.$v.form.username.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "password-input-group",
                    label: _vm.$t("login.password"),
                    "label-for": "password"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "password",
                      name: "password",
                      type: "password",
                      autocomplete: "off",
                      state: _vm.validateState("password"),
                      "aria-describedby": "password-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.password.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.password, "$model", $$v)
                      },
                      expression: "$v.form.password.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "password-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "password_confirm-input-group",
                    label: _vm.$t("register.password_confirm"),
                    "label-for": "password_confirm"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "password_confirm",
                      name: "password_confirm",
                      type: "password",
                      autocomplete: "off",
                      state: _vm.validateState("password_confirm"),
                      "aria-describedby": "password_confirm-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.password_confirm.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.password_confirm, "$model", $$v)
                      },
                      expression: "$v.form.password_confirm.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "password_confirm-live-feedback" } },
                    [
                      !_vm.$v.form.password_confirm.sameAsPassword
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("validation.not_same_password")) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.password_confirm.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "name-input-group",
                    label: _vm.$t("register.name"),
                    "label-for": "name"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "name",
                      name: "name",
                      type: "text",
                      state: _vm.validateState("name"),
                      "aria-describedby": "name-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.name, "$model", $$v)
                      },
                      expression: "$v.form.name.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "name-live-feedback" } },
                    [
                      !_vm.$v.form.name.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "surname-input-group",
                    label: _vm.$t("register.surname"),
                    "label-for": "surname"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "surname",
                      name: "surname",
                      type: "text",
                      state: _vm.validateState("surname"),
                      "aria-describedby": "surname-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.surname.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.surname, "$model", $$v)
                      },
                      expression: "$v.form.surname.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "surname-live-feedback" } },
                    [
                      !_vm.$v.form.surname.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "email-input-group",
                    label: _vm.$t("email"),
                    "label-for": "email"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "email",
                      name: "email",
                      type: "text",
                      state: _vm.validateState("email"),
                      "aria-describedby": "email-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.email.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.email, "$model", $$v)
                      },
                      expression: "$v.form.email.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "email-live-feedback" } },
                    [
                      !_vm.$v.form.email.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.email.email
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.email")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "phone-input-group",
                    label: _vm.$t("register.phone"),
                    "label-for": "phone"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "phone",
                      name: "phone",
                      type: "text",
                      state: _vm.validateState("phone"),
                      "aria-describedby": "phone-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.phone.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.phone, "$model", $$v)
                      },
                      expression: "$v.form.phone.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "phone-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "city-input-group",
                    label: _vm.$t("register.city"),
                    "label-for": "city"
                  }
                },
                [
                  _c(
                    "multiselect",
                    {
                      class: { "is-invalid": _vm.$v.form.city.$error },
                      attrs: {
                        id: "city",
                        "close-on-select": true,
                        "clear-on-select": false,
                        multiple: false,
                        placeholder: _vm.$t("select_placeholder"),
                        "select-label": _vm.$t("multiselect.selectLabel"),
                        "selected-label": _vm.$t("multiselect.selectedLabel"),
                        "deselect-label": _vm.$t("multiselect.deselectLabel"),
                        label: "comune",
                        "track-by": "id",
                        options: _vm.cities,
                        state: _vm.validateState("city"),
                        "aria-describedby": "city_id-live-feedback"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function(ref) {
                            var option = ref.option
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(option.comune) +
                                  " (" +
                                  _vm._s(option.province) +
                                  ") "
                              )
                            ]
                          }
                        },
                        {
                          key: "singleLabel",
                          fn: function(ref) {
                            var option = ref.option
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(option.comune) +
                                  " (" +
                                  _vm._s(option.province) +
                                  ") "
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.$v.form.city.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.form.city, "$model", $$v)
                        },
                        expression: "$v.form.city.$model"
                      }
                    },
                    [
                      _c("template", { slot: "noOptions" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("multiselect.noOptionsLabel")) +
                            " "
                        )
                      ]),
                      _c("template", { slot: "noResult" }, [
                        _vm._v(
                          _vm._s(_vm.$t("multiselect.noResultLabel")) + " "
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "city-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "address-input-group",
                    label: _vm.$t("register.address"),
                    "label-for": "address"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "address",
                      name: "address",
                      type: "text",
                      state: _vm.validateState("address"),
                      "aria-describedby": "address-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.address.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.address, "$model", $$v)
                      },
                      expression: "$v.form.address.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "address-live-feedback" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("validation.max_length")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "street_number-input-group",
                    label: _vm.$t("register.street_number"),
                    "label-for": "street_number"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "street_number",
                      name: "street_number",
                      type: "text",
                      state: _vm.validateState("street_number"),
                      "aria-describedby": "street_number-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.street_number.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.street_number, "$model", $$v)
                      },
                      expression: "$v.form.street_number.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "street_number-live-feedback" } },
                    [
                      !_vm.$v.form.street_number.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "fiscal_code-input-group",
                    label: _vm.$t("register.social_account"),
                    "label-for": "fiscal_code"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "fiscal_code",
                      name: "fiscal_code",
                      type: "text",
                      state: _vm.validateState("fiscal_code"),
                      "aria-describedby": "fiscal_code-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.fiscal_code.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.fiscal_code, "$model", $$v)
                      },
                      expression: "$v.form.fiscal_code.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "fiscal_code-live-feedback" } },
                    [
                      !_vm.$v.form.fiscal_code.required
                        ? _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("validation.required")) + " "
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.form.fiscal_code.minLength
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("validation.min_length")) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c("h3", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.$t("register.notes")))
              ]),
              _c(
                "b-form-group",
                { attrs: { label: "" } },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      rows: "10",
                      placeholder: _vm.$t("register.notes_placeholder"),
                      state: _vm.validateState("notes"),
                      "aria-describedby": "notes-live-feedback"
                    },
                    model: {
                      value: _vm.$v.form.notes.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.notes, "$model", $$v)
                      },
                      expression: "$v.form.notes.$model"
                    }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "notes-live-feedback" } },
                    [_vm._v(" " + _vm._s(_vm.$t("validation.required")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-2 text-right" },
        [
          _c("b-button", { attrs: { type: "submit", variant: "success" } }, [
            _vm._v(_vm._s(_vm.$t("update")) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }