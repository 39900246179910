<template>
  <b-form @submit.stop.prevent="onChildSubmit">
    <div class="mb-4">
      <hr data-content="DATI AFFILIATO" class="hr-text" />
    </div>
    <!-- Username + Legal Name -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="username-input-group"
          :label="$t('username')"
          label-for="username"
        >
          <b-form-input
            id="username"
            name="username"
            type="text"
            readonly
            v-model="$v.form.username.$model"
            :state="validateState('username')"
            aria-describedby="username-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="username-live-feedback">
            <div v-if="!$v.form.username.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="legal_name-input-group"
          :label="$t('register.legal_name')"
          label-for="legal_name"
        >
          <b-form-input
            id="legal_name"
            name="legal_name"
            type="text"
            v-model="$v.form.legal_name.$model"
            :state="validateState('legal_name')"
            aria-describedby="legal_name-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="legal_name-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Email -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="email-input-group"
          :label="$t('email')"
          label-for="email"
        >
          <b-form-input
            id="email"
            name="email"
            type="text"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="email-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="email-live-feedback">
            <div v-if="!$v.form.email.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.email.email">
              {{ $t("validation.email") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Name + Surname -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          id="name-input-group"
          :label="$t('register.name')"
          label-for="name"
        >
          <b-form-input
            id="name"
            name="name"
            type="text"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            aria-describedby="name-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="name-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="surname-input-group"
          :label="$t('register.surname')"
          label-for="surname"
        >
          <b-form-input
            id="surname"
            name="surname"
            type="text"
            v-model="$v.form.surname.$model"
            :state="validateState('surname')"
            aria-describedby="surname-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="surname-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Services Margin + Discount -->
    <!-- <b-form-row>
      <b-col md="6">
        <b-form-group
          id="name-input-group"
          :label="$t('profile.services_margin')"
          label-for="margine"
        >
          <b-form-input
            id="margine"
            name="margine"
            type="text"
            v-model="$v.form.margine.$model"
            :state="validateState('margine')"
            aria-describedby="margine-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="margine-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="prezzo_badante-input-group"
          :label="$t('profile.price_badante')"
          label-for="prezzo_badante"
        >
          <b-form-input
            id="prezzo_badante"
            name="prezzo_badante"
            type="text"
            v-model="$v.form.prezzo_badante.$model"
            :state="validateState('prezzo_badante')"
            aria-describedby="prezzo_badante-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="prezzo_badante-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row> -->
    <!-- VAT Account + Phone -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          id="vat_account-input-group"
          :label="$t('register.vat_account')"
          label-for="vat_account"
        >
          <b-form-input
            id="vat_account"
            name="vat_account"
            type="text"
            v-model="$v.form.vat_account.$model"
            :state="validateState('vat_account')"
            aria-describedby="vat_account-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="vat_account-live-feedback">
            <div v-if="!$v.form.vat_account.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.vat_account.minLength">
              {{ $t("validation.min_length") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="phone-input-group"
          :label="$t('register.phone')"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            name="phone"
            type="text"
            v-model="$v.form.phone.$model"
            :state="validateState('phone')"
            aria-describedby="phone-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="phone-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- Agency
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="agency_id-input-group"
          :label="$t('register.agency')"
          label-for="agency_id"
        >
          <multiselect
            id="agency_id"
            :class="{ 'is-invalid': $v.form.agency_id.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :multiple="false"
            :placeholder="$t('select_placeholder')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            label="name"
            track-by="id"
            :options="agencies"
            v-model="$v.form.agency_id.$model"
            :state="validateState('agency_id')"
            aria-describedby="agency_id-live-feedback"
          >
            <template slot="noOptions">
              {{ $t("multiselect.noOptionsLabel") }}
            </template>
            <template slot="noResult"
              >{{ $t("multiselect.noResultLabel") }}
            </template>
          </multiselect>
          <b-form-invalid-feedback id="agency_id-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row> -->
    <!-- Password + Password Confirmation -->
    <b-form-row>
      <b-col md="6">
        <b-form-group
          id="password-input-group"
          :label="$t('login.password')"
          label-for="password"
        >
          <b-form-input
            id="password"
            name="password"
            type="password"
            autocomplete="off"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="password-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="password-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          id="password_confirm-input-group"
          :label="$t('register.password_confirm')"
          label-for="password_confirm"
        >
          <b-form-input
            id="password_confirm"
            name="password_confirm"
            type="password"
            autocomplete="off"
            v-model="$v.form.password_confirm.$model"
            :state="validateState('password_confirm')"
            aria-describedby="password_confirm-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="password_confirm-live-feedback">
            <div v-if="!$v.form.password_confirm.sameAsPassword">
              {{ $t("validation.not_same_password") }}
            </div>
            <div v-if="!$v.form.password_confirm.required">
              {{ $t("validation.required") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- City + Address -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="city-input-group"
          :label="$t('register.city')"
          label-for="city"
        >
          <multiselect
            id="city"
            :class="{ 'is-invalid': $v.form.city.$error }"
            :close-on-select="true"
            :clear-on-select="false"
            :multiple="false"
            :placeholder="$t('select_placeholder')"
            :select-label="$t('multiselect.selectLabel')"
            :selected-label="$t('multiselect.selectedLabel')"
            :deselect-label="$t('multiselect.deselectLabel')"
            label="comune"
            track-by="id"
            :options="cities"
            v-model="$v.form.city.$model"
            :state="validateState('city')"
            aria-describedby="city_id-live-feedback"
          >
            <template slot="option" slot-scope="{ option }">
              {{ option.comune }} ({{ option.province }})
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.comune }} ({{ option.province }})
            </template>

            <template slot="noOptions">
              {{ $t("multiselect.noOptionsLabel") }}
            </template>
            <template slot="noResult"
              >{{ $t("multiselect.noResultLabel") }}
            </template>
          </multiselect>
          <b-form-invalid-feedback id="city-live-feedback">
            {{ $t("validation.required") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="address-input-group"
          :label="$t('register.address')"
          label-for="address"
        >
          <b-form-input
            id="address"
            name="address"
            type="text"
            v-model="$v.form.address.$model"
            :state="validateState('address')"
            aria-describedby="address-live-feedback"
          >
          </b-form-input>
          <b-form-invalid-feedback id="address-live-feedback">
            <div v-if="!$v.form.address.required">
              {{ $t("validation.required") }}
            </div>
            <div v-if="!$v.form.address.maxLength">
              {{ $t("validation.max_length") }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <div class="mb-2 text-right">
      <!-- Actions -->
      <b-button type="submit" variant="success">{{ $t("update") }} </b-button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  email,
  required,
  sameAs,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AffiliateForm",
  mixins: [validationMixin],
  props: {
    formData: {
      type: Object,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: this.getDefaultFormData(),
      agencies: [],
    };
  },
  computed: {
    ...mapGetters({
      cities: "cities/cities",
    }),
  },
  validations: {
    form: {
      username: {
        required,
      },
      legal_name: {
        required,
      },
      email: {
        required,
        email,
      },
      name: {
        required,
      },
      surname: {
        required,
      },
      /* margine: {
        required,
      },
      prezzo_badante: {
        required,
      }, */
      vat_account: {
        required,
        minLength: minLength(11),
      },
      phone: {
        required,
      },
      password: {
        // required,
      },
      password_confirm: {
        // required,
        sameAsPassword: sameAs("password"),
      },
      city: {
        required,
      },
      address: {
        required,
        maxLength: maxLength(300),
      },
    },
  },
  methods: {
    ...mapActions({
      getCities: "cities/getCities",
    }),
    getDefaultFormData() {
      return {
        id: this.formData.id,
        username: this.formData.username,
        legal_name: this.formData.ragione_sociale,
        email: this.formData.email,
        name: this.formData.first_name,
        surname: this.formData.last_name,
        vat_account: this.formData.p_iva,
        phone: this.formData.phone_number,
        password: "",
        password_confirm: "",
        city_id: this.formData.city_id,
        city: null,
        address: this.formData.address,
        // margine: this.formData.utente.margine,
        // prezzo_badante: this.formData.utente.prezzo_badante,
      };
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onChildSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      // API mapping
      this.form.ragione_sociale = this.form.legal_name;
      this.form.first_name = this.form.name;
      this.form.last_name = this.form.surname;
      this.form.phone_number = this.form.phone;
      this.form.city_id = this.form.city ? this.form.city.id : null;
      this.form.p_iva = this.form.vat_account;
      this.onSubmit(this.form);
    },
  },
  async mounted() {
    if (!this.cities || !this.cities.length) {
      // Load from API
      console.log("Loading cities from API");
      await this.getCities();
    }
    this.form.city = this.cities.find(
      (item) => item.id === parseInt(this.formData.city_id)
    );
  },
};
</script>

<style scoped>
hr.hr-text {
  position: relative;
  border: none;
  height: 1px;
  background: #999;
}

hr.hr-text::before {
  content: attr(data-content);
  display: inline-block;
  background: #fff;
  font-weight: bold;
  font-size: 0.85rem;
  color: #999;
  border-radius: 30rem;
  padding: 0.2rem 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
