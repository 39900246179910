<template>
  <b-overlay :show="updating" rounded="sm">
    <div class="container">
      <div class="row mt-5">
        <div class="col">
          <h2 class="display-4 text-center">{{ $t("menu.profile") }}</h2>
          <component
            ref="currentForm"
            :is="currentForm"
            :form-data="form"
            :on-submit="onSubmit"
          ></component>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import i18n from "@/i18n";
import AffiliateForm from "@/components/forms/profile/AffiliateForm";
import CaregiverForm from "@/components/forms/profile/CaregiverForm";
import CaretakerForm from "@/components/forms/profile/CaretakerForm";
import { userTypes } from "@/services/user.types";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Profile",
  components: { AffiliateForm, CaregiverForm, CaretakerForm },
  metaInfo: {
    titleTemplate: "%s" + i18n.t("menu.profile"),
  },
  data() {
    return {
      typesComponents: {
        [userTypes.affiliate]: "AffiliateForm",
        [userTypes.caretaker]: "CaretakerForm",
        [userTypes.caregiver]: "CaregiverForm",
        null: null,
      },
      currentForm: null,
      form: this.getDefaultFormData(),
    };
  },
  computed: {
    ...mapGetters({
      updating: "account/sendingContactForm",
    }),
  },
  methods: {
    ...mapActions({
      updateProfile: "account/update",
    }),
    getDefaultFormData() {
      return this.$loggedUser();
    },
    onSubmit(form) {
      const formData = { type: this.form.type, ...form };
      console.log("onSubmit", formData);
      this.updateProfile(formData);
    },
  },
  mounted() {
    this.currentForm = this.$loggedUser()
      ? this.typesComponents[this.$loggedUser().type]
      : null;
  },
};
</script>
